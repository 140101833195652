import HeaderMobStyle from "./style/HeaderMobStyle.module.scss";
import * as hm from "./style/HeaderMobStyle.module.scss";
import componentStyleFromPropsMobile from "../../componentStyleFromPropsMobile";

import LogoHolder from "../LogoHolder/LogoHolder";
import LogoHolderMobileStyle from "../LogoHolder/style/LogoHolderMobStyle.module.scss";

import { useState,useContext } from "react";


import Positioner from "../../Positioner";
import Grid from "../../Grid.module.scss";

import HamburgerClosed from "../../icons/List"
import HamburgerOpened from "../../icons/Xlg";

import IconColors from "../../icons/Colors.module.scss";
import IconSizes from "../../icons/Sizes.module.scss";

import MenuMobile from "../MenuMobile/MenuMobile";

import { createContext } from "react";

import Cnv from "../../cnv";

import PositionerFromLeft from './../../PositionerFromLeft';

export default function HeaderMobile({mainContext,setMenuOpen,setHeaderHeight}){  
    const o=useContext(mainContext);
    const [isOpen,setOpen] = useState(o);
   
    const headerMobileContext = createContext(null);
    const {height} = HeaderMobStyle;   
    setHeaderHeight((p)=>p=height);
    
    return (
        <div > 
            <div className={HeaderMobStyle.div+" position-fixed"} >                            
                <div onClick={ ()=> setMenuOpen((p)=>!p)}>              
                    {isOpen ? <HamburgerOpened color={IconColors.white} size={IconSizes.hamburgerClosed}/> : <HamburgerClosed color={IconColors.white} size={IconSizes.hamburgerClosed}/>}
                </div>                         
                <div className={HeaderMobStyle.logoDiv}>                    
                        <PositionerFromLeft fromLeft={120} mode="mobile">
                            <LogoHolder style={LogoHolderMobileStyle} setOpen={setMenuOpen}/>                    
                        </PositionerFromLeft>
                </div>            
            </div>          
        </div>            
    );       
}
