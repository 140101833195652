import H1 from "./H1";
import H2 from "./H2";
import CTG from "./CTG";
import { useContext, useState } from "react";


 

export default function HeadLine({style,setPopup,setPopupText,h1,h2,buttonText,buttonNav,linkTo,entity}){

 //console.log(mode);
 //const o = useContext(MainContext);

 if (buttonNav){
  if (buttonText){
    return (        
      <div className={style.cage}>
          <div className={style.textContainer}>
            <H1 style={style} text={h1}/>
            <H2 style={style} text={h2}/>              
          </div>
          <div className={style.ctgContainer}>           
            <CTG  style={style}  buttonText={buttonText} buttonNav={buttonNav} />                                         
          </div>
      </div>
    );
  }
  if (!buttonText) {
    return (        
      <div className={style.cage}>
          <div className={style.textContainer}>
            <H1 style={style} text={h1}/>
            <H2 style={style} text={h2}/>              
          </div>
          <div>
                                      
          </div>
      </div>
    );
  }
 }
 
 
 if (!buttonNav) {
  if (buttonText){
    return (        
      <div className={style.cage}>
          <div className={style.textContainer}>
            <H1 style={style} text={h1}/>
            <H2 style={style} text={h2}/>              
          </div>
          <div className={style.ctgContainer}>
           
            <CTG style={style} setPopup={setPopup} setPopupText={setPopupText} buttonText={buttonText} linkTo={linkTo} entity={entity}/> 
            
           
                                     
          </div>
      </div>
    );
  }
  if (!buttonText){
    return (        
      <div className={style.cage}>
          <div className={style.textContainer}>
            <H1 style={style} text={h1}/>
            <H2 style={style} text={h2}/>              
          </div>
          <div>
                           
          </div>
      </div>
    );
  }  
 }
 
   
 
    
 
}

