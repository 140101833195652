import Cnv from "./cnv";
export default function componentStyleFromProps(left,top,width,height){
    const styleProps = {
        values:{
             marginLeft:Cnv.w(left),
             marginTop:Cnv.h(top),
             width:Cnv.w(width),
             height:Cnv.h(height),
            // position:"absolute",                       
        },
      }
    return styleProps;
}