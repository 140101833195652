import style from "./style/Modal.module.scss";
export default function Modal({text}) {
    return (        
            <div className={style.modal}>            
                <div className={style.w}>
                    {text}
                </div>
            </div>
       
    );
}