import { useNavigate } from "react-router-dom";

export default function ContactHolder({x,y,style,children}){
    const navigate=useNavigate();
    return (
        <div className={style.contactHolder}  >
           
                {children} +36/20-3191282            
            
        </div>
    );
}