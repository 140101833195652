export default function Face({style,children}){
 return (
 <div className={style}>
  {children}
  
   
 </div>
 );
 
   
}