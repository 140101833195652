import {  useNavigate } from "react-router-dom";


export default function LogoHolder({left,style,border,setOpen}){
   
  const navigate=useNavigate();
    return (
        <div onClick={()=>{navigate("/");window.scrollTo({top:0,left:0,behavior:"auto"});setOpen((p)=>p=false);}} className={style.logoHolder+"  m-0 d-flex justify-content-center align-items-center position-fixed "+border} >
            
                Hidromix Kkt.
            
        </div>
  );
}