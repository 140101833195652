import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function ContactCard({x,y,children,footerStyle,linkTo}){
    const navigate=useNavigate(); 
    return (
        <a href={linkTo} target="_blank" className={footerStyle.link}>
            <div className={x+" "+y+" "+footerStyle.footerContactHolder} >
                {children}                
            </div>
        </a>
    );    
}
