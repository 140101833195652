import Cnv from "../../../cnv";
import componentStyleFromProps from "../../../componentStyleFromProps";
export default function Image({x,y,left,top,width,height,src,imgStyle}){     
    const styleProps = componentStyleFromProps(left,top,width,height);
   
    if (src){
        return (
            <picture>
                <source media="(max-width:500px)" srcSet={src}></source>
                <source media="(min-width:500px)" srcSet={src}></source>
                <img src={src} className={imgStyle+" "+x+" "+y} style={styleProps} srcSet={src}></img>
            </picture>
        );
    }
    else {
        return (        
            <></>
            
            
        );
    }
    
}