import  React, { useState } from "react";
import {useRef,createContext} from "react";
import Positioner from "../Positioner";

import doboz from "../images/doboz.jpg";
import dobozMobile from "../images/dobozMobile.jpg";

import muszerek from "../images/muszerek.jpg";

import akna from "../images/akna.jpg";
import aknaMobile from "../images/aknaMobile.jpg";

import ground from "../images/csotores.png";
import Face from "../components/Face/Face";
import HeadLine from "../components/HeadLine/HeadLine";
import HeadLineMainPageStyle from "../components/HeadLine/styles/HeadLineMainPageStyle.module.scss";
import HeadLineMobMainPageStyle from "../components/HeadLine/styles/HeadLineMobMainPageStyle.module.scss";
import HeadLineNoButtonStyle from "../components/HeadLine/styles/HeadLineNoButtonStyle.module.scss";
import HeadLineMobNoButtonStyle from "../components/HeadLine/styles/HeadLineMobNoButtonStyle.module.scss";
import FaceStyleDesk from "../components/Face/style/FaceStyleDesk.module.scss";
import FaceStyleMob from "../components/Face/style/FaceStyleMob.module.scss";

import ImageHolder from "../components/ImageHolder/imageHolder";
import ImgDeskHldr1 from "../components/ImageHolder/styles/Page2Desktop1.module.scss";
import ImgDeskHldr2 from "../components/ImageHolder/styles/Page2Desktop2.module.scss";
import ImgDeskHldr3 from "../components/ImageHolder/styles/Page2Desktop3.module.scss";
import ImgMobHldr1 from "../components/ImageHolder/styles/Page2Mobile1.module.scss";
import ImgMobHldr2 from "../components/ImageHolder/styles/Page2Mobile2.module.scss";
import ImgMobHldr3 from "../components/ImageHolder/styles/Page2Mobile3.module.scss";






import MenuMobile from "../components/MenuMobile/MenuMobile";

import Grid from "../Grid.module.scss";
import style from "./Page2.module.scss";

import { useOutletContext } from "react-router-dom";

export default function Page2({x,y}){   
  const [isOpen,setOpen,setBodyHeight] = useOutletContext();
  const [windowDimension,setWindowDimension]=useState(false);
  const pageContext = createContext(null);

  React.useEffect(()=>{setWindowDimension(window.innerWidth)});
  React.useEffect(()=>{
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }
    window.addEventListener("resize",handleResize);
    return _ => {
      window.removeEventListener("resize",handleResize);
    }
  }
  );   
  const windowSize=useRef([window.innerWidth,window.innerHeight]);
  const windowWidth=windowSize.current[0];
  const windowHeight=windowSize.current[1];  
  
  if (windowDimension<=500){
    const {heightMobile} = style;
    setBodyHeight((p)=>p=heightMobile);
    return (       
      <div className={style.viewportMobile} >
        <pageContext.Provider value={isOpen} >
            <MenuMobile pageContext={pageContext} setOutOpen={setOpen} />           
        </pageContext.Provider>       
        <div className={style.facesMobile} >
            <Face style={FaceStyleMob.page2Face1}>
                <HeadLine 
                    style={HeadLineMobNoButtonStyle}
                    h1="Ahogy ezt csináljuk"
                    h2="Több év szakmai tapasztalat és professzionális műszerek segítenek feltárni a hiba helyét."
                />
                <ImageHolder imgHldrStyle={ImgMobHldr1} i1={dobozMobile} />         
            </Face>

            <Face style={FaceStyleMob.page2Face2}>
                <HeadLine 
                    style={HeadLineMobNoButtonStyle}
                    h1="Először behatároljuk a hiba helyét"
                    h2="Kezdetként információt gyűjtünk a hálózatról, és kizárjuk a 
                        csőtörésben biztosan nem érintett csöveket.
                        Majd elektroakusztikus műszerrel végzünk vizsgálatot,
                        amely a csőtörés által keltett hangot felerősíti számunkra.
                        Ez segít nekünk kizárni azokat a szakaszokat,
                        ahol feltehetőleg nincs probléma." 
                />
                <ImageHolder imgHldrStyle={ImgMobHldr2} i1={aknaMobile} />
            </Face>   
            
            <Face style={FaceStyleMob.page2Face3}>
                <HeadLine 
                    style={HeadLineMobNoButtonStyle}
                    h1="Megkeressük a hiba pontos helyét"
                    h2="Jelzőgázt engedünk a vízhálózatba. Ez a gáz színtelen szagtalan és egyeltalán nem veszélyes.                 
                        A jelzőgáz felfelé száll, és áthatol a talajon, vagy a padlón.                 
                        Professzionális gázdetektor segítségével megtaláljuk hogy hol jön fel a legnagyobb mennyiségben a gáz.
                        Ez jelzi számunkra a hiba pontos helyét." 
                />
                <ImageHolder imgHldrStyle={ImgMobHldr3} i1={ground} />
            </Face>
        </div>          
      </div>            
      );
  }
  if (windowDimension>500){
    const {heightDesktop} = style;  
    setBodyHeight((p)=>p=heightDesktop);
    return (       
      <div className={x+" "+y+" "+style.viewportDesktop} >         
          <div className={style.facesDesktop} >
            <Face style={FaceStyleDesk.page2Face1}>
                <Positioner x={Grid.c2}>
                  <HeadLine 
                      style={HeadLineMainPageStyle}
                      h1="Ahogy ezt csináljuk"
                      h2="Több év szakmai tapasztalat és professzionális műszerek segítenek feltárni a hiba helyét."
                  />
                </Positioner>
                <Positioner x={Grid.c8}>
                  <ImageHolder  imgHldrStyle={ImgDeskHldr1} i1={doboz} i2={muszerek}/>  
                </Positioner>        
            </Face>

            <Face style={FaceStyleDesk.page2Face2}>
                <Positioner x={Grid.c2}>
                  <HeadLine 
                      style={HeadLineMainPageStyle}
                      h1="Először behatároljuk a hiba helyét"
                      h2="Kezdetként információt gyűjtünk a hálózatról, és kizárjuk a 
                          csőtörésben biztosan nem érintett csöveket.
                          Majd elektroakusztikus műszerrel végzünk vizsgálatot,
                          amely a csőtörés által keltett hangot felerősíti számunkra.
                          Ez segít nekünk kizárni azokat a szakaszokat,
                          ahol feltehetőleg nincs probléma." 
                  />
                </Positioner>
                <Positioner x={Grid.c8}>
                  <ImageHolder imgHldrStyle={ImgDeskHldr2} i1={akna} />
                </Positioner>
            </Face>   
            
            <Face style={FaceStyleDesk.page2Face3}>
                <Positioner x={Grid.c2}>
                  <HeadLine 
                      style={HeadLineNoButtonStyle}
                      h1="Megkeressük a hiba pontos helyét"
                      h2="Jelzőgázt engedünk a vízhálózatba. Ez a gáz színtelen szagtalan és egyeltalán nem veszélyes.                 
                          A jelzőgáz felfelé száll, és áthatol a talajon, vagy a padlón.                 
                          Professzionális gázdetektor segítségével megtaláljuk hogy hol jön fel a legnagyobb mennyiségben a gáz.
                          Ez jelzi számunkra a hiba pontos helyét." 
                  />
                </Positioner>
                <Positioner x={Grid.c8}>
                  <ImageHolder imgHldrStyle={ImgDeskHldr3} i1={ground} />
                </Positioner>
            </Face>
        </div>          
      </div>            
      );
  }
}









