import Cnv from "./cnv";
import {
  Outlet,  
} from "react-router-dom";

import Footer from "./components/Footer/Footer";
import FooterMobile from "./components/FooterMobile/FooterMobile";

import FooterStyle from "./components/Footer/style/FooterStyle.module.scss";
import FooterMobStyle from "./components/FooterMobile/style/FooterMobileStyle.module.scss";

import Grid from "./Grid.module.scss";
import Header from "./components/Header/Header";

import HeaderMobile from "./components/HeaderMobile/HeaderMobile";
import { useState,useEffect,createContext } from "react";

import IconColors from "./icons/Colors.module.scss";
import IconSizes from "./icons/Sizes.module.scss";

import Popup from "./components/Popup/Popup";


import GridDiv from "../src/components/gridDiv/GridDiv";
import "./Main.css";
//export const mContext=createContext(null);

export default function Main(){ 
  const [menuOpen,setMenuOpen] = useState(false);
  const [popupText,setPopupText]= useState();

  const [headerHeight,setHeaderHeight]=useState(0);
  const [bodyHeight,setBodyHeight]= useState(0);
  const [footerHeight,setFooterHeight]=useState(0);  

  
  const [popup,setPopup] = useState(false);

  const [bigScreen,setBigScreen] = useState(window.matchMedia("(min-width:481.0px)").matches);
  const [smallScreen,setSmallScreen] = useState(window.matchMedia("(max-width:480.9px)").matches);
  


  
  useEffect(()=>{    
    function handleResize() {
       setBigScreen(window.matchMedia("(min-width:481.0px)").matches);
       setSmallScreen(window.matchMedia("(max-width:480.9px)").matches);
      
            
    }
    
  
    
    window.addEventListener("resize",handleResize);
    return _ => {
      window.removeEventListener("resize",handleResize);
    }
  }
  );     
  
  if (bigScreen){
   
    return (        
      
      <div className="main" >      
        
          <div className="header" style={{height:Cnv.h(headerHeight)}}>
            <Header setHeaderHeight={setHeaderHeight} /> 
          </div>
          <div className="body" style={{height:Cnv.h(bodyHeight)}}>
            <div className="innerBody" style={{height:Cnv.h(bodyHeight)}}> 
              {/*<GridDiv Grid={Grid} columns={12} rows={15} />*/}
              <Outlet context={[menuOpen,setMenuOpen,setBodyHeight,setPopup,setPopupText]} />  
            </div>            
          </div>  
          <div className="footer" style={{height:Cnv.h(footerHeight)}}>
              <Footer footerStyle={FooterStyle} setPopup={setPopup} setPopupText={setPopupText} iconColors={IconColors.white} iconSizes={IconSizes.footerDesktop} setFooterHeight={setFooterHeight}/>   
          </div>
          {(popup) ? <Popup setPopup={setPopup} popupText={popupText}/> : <></> }
         
       
       
      
      </div>
      
    );
  }
  
  if (smallScreen){
    const mainContext = createContext(null);
    console.log(window.innerWidth);
    
     
    return (        
      <div className="main" >  
        <mainContext.Provider value={menuOpen}>                
          <div className="header" style={{height:Cnv.hMob(headerHeight)}}>
            <HeaderMobile mainContext={mainContext} setMenuOpen={setMenuOpen} setHeaderHeight={setHeaderHeight}/>
          </div>
          <div className="body" style={{height:Cnv.hMob(bodyHeight)}}>
            <div className="innerBody" style={{height:Cnv.hMob(bodyHeight)}}> 
                {/*<GridDiv Grid={Grid} columns={6} rows={100} />*/}
                <Outlet context={[menuOpen,setMenuOpen,setBodyHeight]}/> 
            </div>                 
          </div>  
          <div className="footer" style={{height:Cnv.hMob(footerHeight)}}>
            <FooterMobile  footerStyle={FooterMobStyle} iconColors={IconColors.white} iconSizes={IconSizes.footerMob} setFooterHeight={setFooterHeight}/>
          </div>    
        </mainContext.Provider>           
      </div>       
    );
  }  
}

