import LogoHolder from "../LogoHolder/LogoHolder";
import Menu from "../Menu/Menu";
import ContactHolder from "../ContactHolder/ContactHolder";
import Grid from "../../Grid.module.scss";

import LogoHolderStyle from "../LogoHolder/style/LogoHolderStyle.module.scss";
import ContactHolderStyle from "../ContactHolder/style/ContactHolderStyle.module.scss";
import MenuStyle from "../Menu/style/MenuStyle.module.scss";
import HeaderStyle from "../Header/style/HeaderStyle.module.scss";

import TelephoneOut from "../../icons/TelephoneOut";


import IconColors from "../../icons/Colors.module.scss";
import IconSizes from "../../icons/Sizes.module.scss";
import PositionerFromLeft from "../../PositionerFromLeft";

export default function Header({setHeaderHeight}){
    const {height} = HeaderStyle;
    setHeaderHeight((p)=>p=height);      
    return ( 
        <div className={HeaderStyle.div+" position-fixed"}>
            <PositionerFromLeft fromLeft={120} mode={"desktop"}>
                <LogoHolder style={LogoHolderStyle}  setOpen={()=>{}} /> 
            </PositionerFromLeft>

            <PositionerFromLeft fromLeft={360} mode={"desktop"}>
                <Menu style={MenuStyle} />
            </PositionerFromLeft>

            <PositionerFromLeft fromLeft={1320} mode={"desktop"}>
                <ContactHolder style={ContactHolderStyle}>
                    <TelephoneOut color={IconColors.white} size={IconSizes.contactIcon} />
                </ContactHolder>
            </PositionerFromLeft>
        </div> 
    );
}