import { useNavigate } from "react-router-dom";

import MenuMobStyle from "../MenuMobile/style/MenuMobStyle.module.scss";
import { useContext } from "react";
import { useState } from "react";
import {motion} from "framer-motion";



import Cnv from "../../cnv";

export default function MenuMobile({pageContext,setOutOpen}){
    const navigate=useNavigate();
    const o =useContext(pageContext);
    const [isOpen,setOpen] = useState(o);
  //  console.log(isOpen);
    const startPos=Cnv.wMob(-200);    
    const endPos="0px";     
    if (isOpen){
        return(        
            <div>
                <motion.div className={MenuMobStyle.open} 
                    initial= {{x:startPos}}
                    animate= {{x:endPos}}
                    transition={{ ease: "easeOut", duration: 0.5 }}
                >
                    <div onClick={()=>{navigate("/");window.scrollTo({top:0,left:0,behavior:"auto"});setOutOpen((p)=>(!p))}}>Csőtörés bemérés</div>      
                        {  /*useCallback kell!! */} 
                    <div onClick={()=>{navigate("/page2");window.scrollTo({top:0,left:0,behavior:"auto"});setOutOpen((p)=>(!p))}}>Ahogy ezt csináljuk</div> 
                </motion.div> 
                <div className={MenuMobStyle.overlay} onClick={()=>setOutOpen((p)=>!p)}>   
                </div>
            </div>
    );
    }   
}