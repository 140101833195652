import ContactCard from "./ContactCard/ContactCard";
import EnvelopeFill from "../../icons/EnvelopeFill";
import Facebook from "../../icons/Facebook";
import TelephoneOut from "../../icons/TelephoneOut";

export default function Footer({x,y,footerStyle,setPopup,setPopupText,iconColors,iconSizes,setFooterHeight}){
 const {height}=footerStyle;
    setFooterHeight((p)=>p=height);     
    return (
        <div className={x+" "+y+" "+footerStyle.div+" "} >          
           <ContactCard footerStyle={footerStyle} setPopup={setPopup} setPopupText={setPopupText} text="csotoreskeresesbontasnelkul@gmail.com" ><EnvelopeFill  color={iconColors} size={iconSizes}/></ContactCard>
           <ContactCard footerStyle={footerStyle} setPopup={setPopup} setPopupText={setPopupText} text="+36/20-3191282" ><TelephoneOut color={iconColors} size={iconSizes}/></ContactCard>
           <ContactCard footerStyle={footerStyle} setPopup={setPopup} setPopupText={setPopupText} text="Facebook" linkTo="https://www.facebook.com/06203191282Szuklics"><Facebook color={iconColors} size={iconSizes}/></ContactCard>         
        </div>
    );
   
}