import dimensions from "./pxToPercent.scss";
export default class Cnv{    
    
   
     
    static w(pix) {        
        const {baseW}= dimensions;       
        var w=100*(pix/baseW);
        return w+"vw";
    }
    static h(pix) {
        const {baseH}=dimensions;
        var h=100*(pix/baseH);
        return h+'vh';
    }
    static wMob(pix) {        
        const {baseWMob}=dimensions;        
        var w=100*(pix/baseWMob);
        return w+"vw";
    }   
    static hMob(pix) {
        const {baseHMob}=dimensions;
        var h=100*(pix/baseHMob);
        return h+'vh';
    } 
}