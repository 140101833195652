import { Link,useNavigate } from "react-router-dom";
import MenuStyle from "./style/MenuStyle.module.scss";
import componentStyleFromProps from "../../componentStyleFromProps";
export default function Menu({x,y,style,border}){
    
    const navigate = useNavigate();
    return (       
            <div className={style.menu+"   "+x+" "+y} >          
                <div onClick={()=>{navigate("/");window.scrollTo({top:0,left:0,behavior:"auto"});}}>Csőtörés bemérés</div>
                <div onClick={()=>{navigate("/page2");window.scrollTo({top:0,left:0,behavior:"auto"});}}>Ahogy ezt csináljuk</div> 
            </div>           
    );
}
