import Cnv from "../../cnv";
import Image from "../ImageHolder/Image/Image";
import componentStyleFromProps from "../../componentStyleFromProps";
export default function imageHolder({i1,i2,i3,i4,imgHldrStyle}){
    
     
    return (
        <div className={imgHldrStyle.imgHolder} >
           <Image src={i1} imgStyle={imgHldrStyle.img1}/>
       

           <Image src={i2} imgStyle={imgHldrStyle.img2}/>
          { <Image src={i3} imgStyle={imgHldrStyle.img3}/>}
           {<Image src={i4}  imgStyle={imgHldrStyle.img4}/>}
           

            
          
        </div>
    );
}
