import { useNavigate } from "react-router-dom";

export default function ContactCard({x,y,text,setPopup,setPopupText,children,footerStyle,linkTo}){
    const navigate=useNavigate();  
    if (linkTo) {
        return (
            <a href={linkTo} target="_blank" className={footerStyle.link}>
                <div className={x+" "+y+" "+footerStyle.footerContactHolder} >
                    {children}
                    <div className={footerStyle.text} >{text}</div>
                </div>
            </a>
        ); 
    }
    if (!linkTo) {
        return ( 
            <div>        
                <div onClick={()=>{setPopup(true);setPopupText(text);console.log("e")}} className={x+" "+y+" "+footerStyle.footerContactHolder} >                    
                    {children}
                    <div className={footerStyle.text} >{text}</div>
                </div>                
            </div>       
        );
    }    
}