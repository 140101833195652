import style from "./style/Popup.module.scss";
import { useContext, useState } from "react";
import Modal from "../Modal/Modal";


export default function Popup({setPopup,popupText}){ 
        return(
            <div onClick={()=>{setPopup(false)}} className={style.popup}>
                <Modal text={popupText}></Modal> 
            </div>
        );
   
}