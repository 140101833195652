import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';


import {
  createBrowserRouter,
  RouterProvider,   
} from "react-router-dom";


import "bootstrap/dist/css/bootstrap.css";

import Main from "./Main";

import Page1 from "./Page1/Page1";
import Page2 from "./Page2/Page2";

import Grid from "./Grid.module.scss";





const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {           
        index:true,element: <Page1 x={Grid.c1} y={Grid.r1} />  ,      
      },
      {
        path: "/page2",
        element: <Page2 Page1 x={Grid.c1} y={Grid.r1} />,
      },
         
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);