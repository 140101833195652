
import  React, { useState,useEffect } from "react";
import {useRef,createContext} from "react";
import Positioner from "../Positioner";

import muszer from "../images/muszer.png";
import muszerMobile from "../images/muszer-mobile.jpg";

import godor from "../images/cuttededitedgodor.jpg";
import godorMobile from "../images/cuttededitedgodorMobile.jpg";


import flowmeter from "../images/flow-meter-1568558.png";
import flowmeterMobile from "../images/flow-meter-1568558Mobile.png";



import HeadLine from "../components/HeadLine/HeadLine";
import HeadLineMainPageStyle from "../components/HeadLine/styles/HeadLineMainPageStyle.module.scss";
import HeadLineMobMainPageStyle from "../components/HeadLine/styles/HeadLineMobMainPageStyle.module.scss";
import HeadLineNoButtonStyle from "../components/HeadLine/styles/HeadLineNoButtonStyle.module.scss";
import HeadLineMobNoButtonStyle from "../components/HeadLine/styles/HeadLineMobNoButtonStyle.module.scss";
import FaceStyleDesk from "../components/Face/style/FaceStyleDesk.module.scss";
import FaceStyleMob from "../components/Face/style/FaceStyleMob.module.scss";



import Face from "../components/Face/Face";
import ImageHolder from "../components/ImageHolder/imageHolder";
import ImgDeskHldr1 from "../components/ImageHolder/styles/Page1Desktop1.module.scss";
import ImgDeskHldr2 from "../components/ImageHolder/styles/Page1Desktop2.module.scss";
import ImgDeskHldr3 from "../components/ImageHolder/styles/Page1Desktop3.module.scss";
import ImgMobHldr1 from "../components/ImageHolder/styles/Page1Mobile1.module.scss";
import ImgMobHldr2 from "../components/ImageHolder/styles/Page1Mobile2.module.scss";
import ImgMobHldr3 from "../components/ImageHolder/styles/Page1Mobile3.module.scss";

import MenuMobile from "../components/MenuMobile/MenuMobile";

import Grid from "../Grid.module.scss";
import style from "./Page1.module.scss";

import { useOutletContext } from "react-router-dom";
export const pageContext = createContext(null);
export const MainContext = createContext(null);



export default function Page1({x,y}){   
  const [isOpen,setOpen,setBodyHeight,setPopup,setPopupText] = useOutletContext();
  const [bigScreen,setBigScreen] = useState(window.matchMedia("(min-width:481.0px)").matches);
  const [smallScreen,setSmallScreen] = useState(window.matchMedia("(max-width:480.9px)").matches);

  useEffect(()=>{    
    function handleResize() {
       setBigScreen(window.matchMedia("(min-width:481.0px)").matches);
       setSmallScreen(window.matchMedia("(max-width:480.9px)").matches);
            
    }
    
  
    
    window.addEventListener("resize",handleResize);
    return _ => {
      window.removeEventListener("resize",handleResize);
    }
  }
  );     
 
 

  if (smallScreen){
    const {heightMobile} = style;
    setBodyHeight((p)=>p=heightMobile);
    
    
    return (       
      <div className={style.viewportMobile} >
        <pageContext.Provider value={isOpen} >
          <MenuMobile pageContext={pageContext} setOutOpen={setOpen} />           
        </pageContext.Provider>       
         
        <div className={style.facesMobile} >
          <Face style={FaceStyleMob.page1Face1}>
            <HeadLine 
              style={HeadLineMobMainPageStyle} 
              h1="Megtaláljuk a csőtörést"
              h2="Műszeres beméréssel meghatározzuk a víz szivárgásának pontos helyét." 
              buttonText="Hívjon!" 
              linkTo="tel:+36203191282" 
            />
            <ImageHolder imgHldrStyle={ImgMobHldr1} i1={muszerMobile} />          
          </Face>

          <Face style={FaceStyleMob.page1Face2}>
            <HeadLine 
              style={HeadLineMobMainPageStyle} 
              h1="Nem kell feltárni az egész csővezetéket" 
              h2="Mi megmutatjuk hogy hol szükséges bontani a hiba könnyű javításának érdekében. " 
              buttonText="Ahogy csináljuk" 
              buttonNav="/page2" 
            />
            <ImageHolder imgHldrStyle={ImgMobHldr2} i1={godorMobile} />
          </Face>   
            
          <Face style={FaceStyleMob.page1Face3}>
            <HeadLine 
              style={HeadLineMobNoButtonStyle} 
              h1="Mikor keressen minket?" 
              h2="Megnövekedett vízfogyasztás vagy vizesedés jelezheti a bajt." 
              buttonText="Tudjon meg többet!"  
              />
              <ImageHolder imgHldrStyle={ImgMobHldr3} i1={flowmeterMobile} />
          </Face>
        </div>          
      </div>            
      );
  }
  if (bigScreen){
    const {heightDesktop} = style;  
    setBodyHeight((p)=>p=heightDesktop);    

    return (       
      <div className={x+" "+y+" "+style.viewportDesktop} >         
        <div className={style.facesDesktop} >
          <Face style={FaceStyleDesk.page1Face1}>
            <Positioner x={Grid.c2}>
              <HeadLine 
                style={HeadLineMainPageStyle} 
                h1="Megtaláljuk a csőtörést"
                h2="Műszeres beméréssel meghatározzuk a víz szivárgásának pontos helyét." 
                buttonText="Hívjon!" 
                linkTo="tel:+36306435809" 
                />
            </Positioner>
            <Positioner x={Grid.c8} left="-42">
              <ImageHolder imgHldrStyle={ImgDeskHldr1} i1={muszer} />
            </Positioner>         
          </Face>

          <Face style={FaceStyleDesk.page1Face2}>
            <Positioner x={Grid.c2}>
              <ImageHolder imgHldrStyle={ImgDeskHldr2} i1={godor} />
            </Positioner>
            <Positioner x={Grid.c8} left="-42">
              <HeadLine 
                style={HeadLineMainPageStyle} 
                h1="Nem kell feltárni az egész csővezetéket" 
                h2="Mi megmutatjuk hogy hol szükséges bontani a hiba könnyű javításának érdekében. " 
                buttonText="Ahogy csináljuk" 
                buttonNav="/page2" 
              />           
            </Positioner>            
          </Face>   
            
          <Face style={FaceStyleDesk.page1Face3}>
            <Positioner x={Grid.c2}>
              <HeadLine 
                style={HeadLineNoButtonStyle} 
                h1="Mikor keressen minket?" 
                h2="Megnövekedett vízfogyasztás vagy vizesedés jelezheti a bajt." 
                buttonText="Tudjon meg többet!"  
              />
            </Positioner>
            <Positioner x={Grid.c8}>
              <ImageHolder imgHldrStyle={ImgDeskHldr3} i1={flowmeter} left="-42"/>
            </Positioner>
          </Face>
        </div>        
          
      </div>            
      );
  }
}









