import Cnv from "./cnv";
export default function PositionerFromLeft({fromLeft,mode,children}){
    if (mode=="desktop"){
       // console.log("desktop");
        return(
            <div style={{position:"absolute",left:Cnv.w(fromLeft)}}>
            {children}
            </div>
        );
    }
    if (mode=="mobile"){
        return(
            <div style={{position:"absolute",left:Cnv.wMob(fromLeft)}}>
            {children}
            </div>
        );
    }
   
}