import ContactCardMobile from "./ContactCardMobile/ContactCardMobile";
import EnvelopeFill from "../../icons/EnvelopeFill";
import Facebook from "../../icons/Facebook";
import TelephoneOut from "../../icons/TelephoneOut";

export default function Footer({x,y,footerStyle,setPopup,setPopupText,iconColors,iconSizes,setFooterHeight}){
 const {height}=footerStyle;
    setFooterHeight((p)=>p=height);     
    return (
        <div className={x+" "+y+" "+footerStyle.div+" "} >          
           <ContactCardMobile footerStyle={footerStyle} linkTo="mailto:csotoreskeresesbontasnelkul@gmail.com"><EnvelopeFill  color={iconColors} size={iconSizes}/></ContactCardMobile>
           <ContactCardMobile footerStyle={footerStyle} linkTo="tel:+36/20-3191282"><TelephoneOut color={iconColors} size={iconSizes}/></ContactCardMobile>
           <ContactCardMobile footerStyle={footerStyle} linkTo="https://www.facebook.com/06203191282Szuklics"><Facebook color={iconColors} size={iconSizes}/></ContactCardMobile>         
        </div>
    );
   
}