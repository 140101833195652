import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import componentStyleFromProps from "../../componentStyleFromProps";

import Modal from "../Modal/Modal";
import { useContext, useState } from "react";











//import {MainContext} from "../../Page1/Page1";




export default function CTG({x,y,style,setPopup,setPopupText,buttonText,buttonNav,linkTo,entity}){   
   
    const navigate=useNavigate();
    
   // const o = useContext(MainContext);
   // const [popup,setPopup] = useState(false);
    
    
    if (buttonNav) {     
        return (
            <div onClick={()=>{navigate(buttonNav);window.scrollTo({top:0,left:0,behavior:"auto"});}} className={style.button+" "+x+" "+y}>{buttonText}</div>                
        );           
    }
    if (!buttonNav) {
        if (linkTo){
            return(<a href={linkTo} target="_blank" className={style.link}><div className={style.button+" "+x+" "+y}>{buttonText}</div></a>   );
        }
        if (!linkTo){
            return(
                <div>        
                <div  onClick={()=>{setPopup(true);setPopupText(entity);}} className={style.button+" "+x+" "+y} >                    
                    {buttonText}
                </div> 
                
            </div>       
            );
            
        }
       
        
            
    }
    
}
